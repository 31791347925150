import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorNotification, showSuccessNotification } from '../shared/notifications';
import { useForm, zodResolver } from '@mantine/form';
import { z } from 'zod';
import { setSingleCookie } from '../../proxy-server-cookie';
import { MAIN_ASSET_QUERY_KEY } from '../../assets/hooks/useAssetsQuery';
import { WORKFLOWS_QUERY_MAIN_KEY } from '../../workflows/components/table/query/useWorkflowsQuery';
import { useGrowthbookContext } from '../../contexts/GrowthbookContext';
import { MAIN_PUBLISHED_QUERY_KEY } from '../../published/hooks/usePublishedQuery';
import { isImpersonationErrorObj } from '../../../utils/isImpersonationErrorObj';
import { IMPERSONATION_MAIN_QUERY_KEY } from '../../../shared/useImpersonationQuery';
import { canImpersonate } from '../../../utils/canImpersonate';
import { getUserWithImpersonation } from '@/app/(actions)/user/getUser';
import { useUserQuery } from '@/shared/hooks/useUserQuery';

const formSchema = z.object({
  email: z.string().email('Invalid email'),
});

export function ImpersonateUserModal({ opened, close }: { opened: boolean; close: () => void }) {
  const { data: user } = useUserQuery();
  const queryClient = useQueryClient();
  const growthbook = useGrowthbookContext();

  const isImpersonateOn = growthbook?.isOn('impersonate-user');
  const isImpersonationAvailable = canImpersonate(user);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      email: '',
    },
    validate: zodResolver(formSchema),
  });

  const { mutateAsync: impersonate, isPending } = useMutation({
    mutationFn: async (email: string) => {
      if (user?.email === email) {
        throw new Error('You cannot impersonate yourself');
      }
      const res = await getUserWithImpersonation(email);

      if (isImpersonationErrorObj(res)) {
        throw new Error(res.error.message);
      }

      setSingleCookie('impersonateEmail', email);
      await queryClient.invalidateQueries({
        predicate: (query) =>
          [MAIN_ASSET_QUERY_KEY, WORKFLOWS_QUERY_MAIN_KEY, MAIN_PUBLISHED_QUERY_KEY, IMPERSONATION_MAIN_QUERY_KEY].some(
            (key) => query.queryKey.includes(key)
          ),
      });
      return email;
    },
    onSuccess: (email) => {
      showSuccessNotification({
        title: 'Impersonation successful',
        message: `You are now impersonating ${email}`,
      });
      close();
    },
    onError: (error) => {
      form.setFieldError('email', error.message);
      showErrorNotification({
        title: 'Impersonation failed',
        message: error.message,
      });
    },
  });

  if (!isImpersonateOn || !isImpersonationAvailable) {
    return null;
  }

  return (
    <Modal opened={opened} onClose={close} title='Impersonate User'>
      <form onSubmit={form.onSubmit((values) => impersonate(values.email))}>
        <Stack gap='xs'>
          <Text>Enter the email address of a user to impersonate.</Text>
          <TextInput
            placeholder='Email address'
            type='email'
            data-autofocus
            {...form.getInputProps('email')}
            key={form.key('email')}
          />
          <Text size='sm'>
            <strong>Note:</strong> Models and embeds can be viewed in read-only. Every other page shows your data.
          </Text>
          <Group gap='xs' justify='flex-end' w='100%'>
            <Button type='submit' loading={isPending}>
              Impersonate
            </Button>
            <Button variant='default' onClick={close}>
              Cancel
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
